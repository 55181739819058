
import Vue from 'vue';
import NoChartData from '../no-data/NoChartData.vue';
import utils from '../../../../util';
import { EChartsOption, LegendOption } from 'echarts/types/dist/shared';
import { Tooltip } from '../../../../../../../shared/dashboardLayouts/layout-components/types/layoutTypes';
import { getCssVar } from '@c360/ui';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ECharts = (window as any).echarts || undefined;
if (!ECharts) {
  // eslint-disable-next-line no-console
  console.error('ECharts is not defined');
}

let unwatchDataChanges: () => void;

export default Vue.extend({
  inheritAttrs: false,
  name: 'genericLineChart',
  components: { NoChartData },
  props: [
    'selectedRange',
    'loading',
    'percentage',
    'metricTitle',
    'sectionConfig',
    'chartData',
    'total',
    'componentConfig',
    'title',
    'subTitle',
    'icon',
    'chartType',
    // 'commonTweaks',
    'dataSource',
    'dataKey',
    'theme',
    'isExporting',
    'isExportDynamic',
    'exportData',
    'exportContext',
    'componentHeight',
    'isClickable',
    'isActive',
    'isComingSoon',
    'tooltips',
  ],
  data: (): {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chartInstance: any;
    commonTweaks: any;
    canvasWidth: string;
    canvasHeight: string;
    legendSelected: LegendOption | null;
    selectedLine: string | null;
    dropdownMenuOpen: boolean;
    menuLegendKeys: string[];
  } => ({
    commonTweaks: ['datebackfill'],
    chartInstance: undefined,
    canvasWidth: 'auto',
    canvasHeight: '60px',
    legendSelected: null,
    selectedLine: null,
    dropdownMenuOpen: false,
    menuLegendKeys: [],
  }),
  watch: {
    chartData: {
      immediate: true,
      handler(): void {
        this.initChart();
        if (
          this.chartData?.length === 0 &&
          this.componentConfig.hideIfNoData &&
          !this.$store.state.layoutEditor.editMode
        ) {
          this.$emit('set-display', false);
        } else {
          this.$emit('set-display', true);
        }
      },
    },
    '$store.state.customer.theme': 'initChart',
    // custimaztion changes
    showOneLine: {
      handler(): void {
        this.initChart();
      },
    },
    chartColors: {
      handler(): void {
        this.initChart();
      },
    },
    // breakpoints: {
    //   handler(): void {
    //     this.onWindowResize();
    //   },
    // },
  },
  mounted() {
    window.addEventListener('optimizedResize', this.onWindowResize);
    unwatchDataChanges = utils.fireOnAdDataChange(this, this.initChart, true);
    // setTimeout(() => {
    //   console.log(this.componentConfig, this.componentConfig.lineSeriesKeys);
    // }, 500);
    if (this.componentConfig.selectedLineSeriesKey) {
      this.selectedLine = this.componentConfig.selectedLineSeriesKey;
    } else if (this.componentConfig.lineSeriesKeys?.length > 0) {
      this.selectedLine = this.componentConfig.lineSeriesKeys[0];
    }
    this.menuLegendKeys = this.lineSeriesKeys;
    this.initChart();
  },
  beforeDestroy() {
    unwatchDataChanges();
    window.removeEventListener('optimizedResize', this.onWindowResize);
  },
  computed: {
    isOrderList() {
      return this.$route.query.tab === 'orderlist';
    },
    isTooltipVisible() {
      return !!this.tooltipContent;
    },
    tooltipContent(): string | null {
      if (!this.tooltips?.length) return null;
      if (this.isOrderList) {
        return this.tooltips[0].tooltip;
      }
      const { title } = this.tooltips[0] || { title: '' };
      const namesHtml = this.tooltips
        .slice(1)
        .map(item => `<li>${item.name}</li>`)
        .join('');
      return `${title} <br /> <ol>${namesHtml}</ol>`;
    },
    showNoDataChart(): boolean {
      if (this.$store.state.layoutEditor.editMode) {
        return true;
      } else if ((this.componentConfig.hideIfNoData && !this.hasEnoughData && this.loading) || this.isPrinting) {
        return false;
      }
      return true;
    },
    isPrinting() {
      return this.$route.query.print === 'true';
    },
    isNewHome(): boolean {
      return this.componentConfig.dataSource === 'HOME.smallChartMetrics';
    },
    tooltip(): Array<Tooltip> | null {
      // allows an override of the tooltip configured in the layout file
      if (this.componentConfig.tooltips) return this.componentConfig.tooltips;

      // otherwise get tooltip based on selected metric
      const activeMetric = this.selectedLine?.toLowerCase();

      // convert to the expected array
      return [utils.getTooltipsFromMetric(activeMetric)] || null;
    },
    containerClass(): string[] {
      const classes = this.isExporting ? ['exporting-container'] : ['container'];
      if (this.sideMetricsConfig) {
        classes.push('has-side-metrics');
      }

      return classes;
    },
    getInterval(): number {
      if (this.componentConfig.xAxisIntervalCount) {
        if (!this.chartData[0]) return 0;
        return Math.floor(this.chartData[0].length / this.componentConfig.xAxisIntervalCount);
      } else return this.componentConfig.xAxisShowAll ? 0 : 1;
    },
    hasEnoughData(): boolean {
      if (this.$store.state.layoutEditor.editMode) return true;
      return this.chartData && this.chartData[0]?.length > 0;
    },
    canExportToXLS(): boolean {
      if (this.$store.state.customer.currentSection?.xlsExportLocalOnly) {
        if (!utils.isLocalDev()) {
          return false;
        }
      }
      if (this.$store.state.customer.currentSection?.xlsExportDevOnly) {
        if (!utils.isLocalDev() && !utils.isDevelopment()) {
          return false;
        }
      }
      return this.hasEnoughData && !!this.componentConfig?.exportableTableData;
    },
    isXLS(): boolean {
      return this.exportData && this.exportData.layout && this.exportData.layout.fileType === 'XLS';
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    canvasStyle(): any {
      return {
        width: this.canvasWidth,
        height: this.canvasHeight,
      };
    },
    sourceData() {
      let data;
      // check for individual data
      const hasIndivdualCampaignData = this.tweaks?.includes('indivdual');
      if (hasIndivdualCampaignData) {
        data = utils.adDataForKey(this, `${this.dataSource}Campaign`);
        if (!Array.isArray(data)) {
          data = utils.adDataForKey(this, this.dataSource);
        }
      } else {
        data = utils.adDataForKey(this, this.dataSource);
      }
      return data;
    },
    limitChartsEnoughData(): boolean {
      if (!Array.isArray(this.sourceData)) {
        return false;
      }
      return this.sourceData.length === 1;
    },
    lineSeriesKeys(): string[] {
      let lineSeriesKeys = this.componentConfig.lineSeriesKeys;
      if (!Array.isArray(lineSeriesKeys)) {
        if (typeof lineSeriesKeys === 'string') {
          lineSeriesKeys = [lineSeriesKeys];
        } else {
          lineSeriesKeys = [];
        }
      }
      const hideEmptySeriesKeys = this.componentConfig.hideEmptySeriesKeys;
      if (hideEmptySeriesKeys) {
        const rows = this.sourceData;
        // remove keys that don't have any data
        hideEmptySeriesKeys.forEach((k: string) => {
          if (!rows.find(x => !!x[k])) {
            lineSeriesKeys = lineSeriesKeys.filter(x => x !== k);
          }
        });
      }

      // order to make selected line last so non-selected dont overlap
      if (lineSeriesKeys.length > 1 && this.selectedLine) {
        const keys: string[] = [this.selectedLine];
        lineSeriesKeys.forEach((key: string) => {
          if (key !== this.selectedLine) keys.push(key);
        });
        lineSeriesKeys = keys;
      }
      return lineSeriesKeys;
    },
    chartConfig(): EChartsOption {
      let lineColor, textColor;
      if (this.Theme.config.lineCharts?.style === 'minimal') {
        lineColor = '#fff';
        textColor = '#000';
      } else {
        lineColor = '#000';
        textColor = lineColor;
      }
      if (this.isExporting) {
        lineColor = '#333';
      }

      let colorset = this.colorset;
      if (this.componentConfig.useChartColorIndex > -1) {
        colorset = [this.colorset[this.componentConfig.useChartColorIndex]];
      }

      const styleConfig = {
        dash: {
          legend: {
            textStyle: {
              color: textColor,
            },
            align: 'left',
            left: 'left',
          },
          grid: {
            // containLabel: false,
            left: '0%',
            top: '10%',
            bottom: '10%',
            right: '10%',
            width: '90%',
            height: '50px',
          },
        },
        c360: {
          legend: {
            align: 'left',
            left: 'left',
            textStyle: {
              fontWeight: 600,
            },
          },
          grid: {
            containLabel: true,
            left: 'center',
            bottom: '10%',
            top: '10%',
            width: '92%',
            height: 'auto',
          },
        },
      };
      const currentStyle = styleConfig.dash;

      const formatterForCPR = {
        formatter: function (params) {
          try {
            let tooltipText = `${params[0].axisValueLabel}<br>`;
            params.forEach((param: any) => {
              tooltipText += `${param.marker} ${param.seriesName}<b class="ml-4">${(param.value * 100).toFixed(
                2,
              )}%</b><br>`;
            });
            return tooltipText;
          } catch (exp) {
            // eslint-disable-next-line no-console
            console.error('percent tooltip formatter', exp, params.value);
          }
        },
      };

      const cfg: EChartsOption = {
        tooltip: {
          trigger: 'axis',
          backgroundColor: '#FFFFFF',
          borderWidth: 0,
          textStyle: {
            color: '#3E5769',
          },
        },
        title: {
          show: false,
          textStyle: {
            color: textColor,
            fontFamily: 'sans-serif',
          },
        },
        grid: this.isExporting
          ? {
              containLabel: true,
              left: this.componentConfig.canvasPosition?.left || 10,
              top: this.componentConfig.canvasPosition?.top || 10,
              right: this.componentConfig.canvasPosition?.right || 10,
              bottom: this.componentConfig.canvasPosition?.bottom || 10,
              height: this.componentConfig.canvasPosition?.height,
              width: this.componentConfig.canvasPosition?.width,
            }
          : currentStyle.grid,
        xAxis: {
          show: this.isExporting || this.Theme.config.lineCharts?.xAxis?.show !== false,
          data: this.chartData?.[0],
          type: 'category',
          boundaryGap: false,
          axisLine: {
            show: false,
            lineStyle: {
              color: lineColor,
              type: 'solid',
            },
          },
          axisTick: {
            show: this.isExporting,
            alignWithLabel: false,
            lineStyle: {
              color: lineColor,
              type: 'solid',
            },
          },
          max: parseInt(this.componentConfig.xAxisMaxItems) || 'dataMax',
          axisLabel: {
            show: false,
            color: textColor,
            interval: this.getInterval,
            rotate: typeof this.componentConfig.xAxisRotate !== 'undefined' ? this.componentConfig.xAxisRotate : 0,
          },
        },
        color: colorset,
        height: 'auto',
        width: '99%',
      };

      if (['CTR', 'VCR'].includes(this.metricTitle)) {
        cfg.tooltip.formatter = formatterForCPR.formatter;
      }

      if (Array.isArray(this.chartData) && this.chartData.length) {
        // line series
        cfg.series = this.lineSeriesKeys.reduce((lines: object[], key: string, index: number) => {
          const line = {
            type: 'line',
            selectMode: false,
            animation: !this.isExporting,
            name: this.getLegendKeyName(key),
            yAxisIndex: index,
            data: this.chartData[index + 1],
            smooth: true,
            symbol: 'circle',
            symbolSize: 1,
            zlevel: key === this.selectedLine ? 3 : 1,
            areaStyle:
              key === this.selectedLine
                ? {
                    color: {
                      type: 'linear',
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      colorStops: [
                        {
                          offset: 0,
                          color: getCssVar('--c360-color-compulse'),
                        },
                        {
                          offset: 1,
                          color: '#ffffff', // color at 100%
                        },
                      ],
                      global: false, // default is false
                    },
                    opacity: this.isNewHome ? 0.5 : 0.1,
                  }
                : { color: 'none' },
          };

          if (this.showOneLine && key === this.selectedLine) {
            lines.push(line);
          } else if (!this.showOneLine) {
            lines.push(line);
          }
          return lines;
        }, []);
      }

      // y-axis
      cfg.yAxis = this.lineSeriesKeys.reduce((lines: object[], key: string) => {
        lines.push({
          show: key === this.selectedLine,
          type: 'value',
          splitLine: {
            show: false,
          },
          scale: !!this.componentConfig.scale,
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
            color: textColor,
            padding: [0, 0, 0, 0],
            formatter: function (value) {
              try {
                if (key === 'CompletionRate') {
                  // Add percent to completion rate.
                  return `${value}%`;
                }
                if (typeof value === 'number') {
                  return utils.formatNumberWithCommas(value); // .toLocaleString();
                }
                return value;
              } catch (exp) {
                // eslint-disable-next-line no-console
                console.error('yAxislabel formatter', exp, value);
              }
              return value;
            },
          },
        });
        return lines;
      }, []);

      // tooltip
      if (this.componentConfig.tooltipType && this.componentConfig.tooltipType !== 'default') {
        cfg.tooltip = {
          formatter: this.tooltip,
          trigger: 'axis',
          show: true,
          backgroundColor: 'rgb(0, 0, 0, 0.5)',
          borderWidth: 0,
          textStyle: {
            color: 'rgb(255, 255, 255, 1)',
          },
        };
      }

      // legend keys
      const legendKeys: string[] = [];
      this.componentConfig.lineSeriesKeys?.forEach((key: string) => {
        legendKeys.push(this.getLegendKeyName(key));
      });

      cfg.legend = {
        // show: this.componentConfig.hideLegend !== true,
        show: false,
        data: legendKeys,
        selectedMode: false,
        icon: 'circle',
        ...currentStyle.legend,
      };

      if (this.isExporting) {
        if (typeof this.componentConfig?.legend?.orient !== 'undefined') {
          cfg.legend.orient = this.componentConfig.legend.orient;
        }
        if (typeof this.componentConfig?.legend?.align !== 'undefined') {
          cfg.legend.align = this.componentConfig.legend.align;
        }
        if (typeof this.componentConfig?.legend?.left !== 'undefined') {
          cfg.legend.left = this.componentConfig.legend.left;
        }
        if (typeof this.componentConfig?.legend?.top !== 'undefined') {
          cfg.legend.top = this.componentConfig.legend.top;
        }
      }

      return cfg;
    },
    colorset(): string[] {
      const colors: string[] = [];
      const greyColors = ['#c6c6c6', '#5f5e5e', '#a6a6a6', '#000000', '#ff0000'];
      let greyColorIndex = 0;
      const colorIndex = this.componentConfig.lineSeriesKeys?.indexOf(this.selectedLine);
      let colorArray = this.Theme.config.chartColors;
      if (this.componentConfig.chartColors) {
        // use configurability override if defined
        colorArray = this.componentConfig.chartColors;
      }

      let lineColor = colorArray[colorIndex] || '#1e5380';
      if (this.componentConfig.chartColorIndex) {
        // a way to override color from layout config
        lineColor = colorArray[this.componentConfig.chartColorIndex];
      }

      lineColor = getCssVar('--c360-color-compulse');

      // we need to look at the computed lineSeriesKeys for web
      this.lineSeriesKeys.forEach((key: string) => {
        if (!this.showOneLine && key !== this.selectedLine) {
          const grey = greyColors[greyColorIndex];
          colors.push(grey);
          greyColorIndex += 1;
        } else colors.push(lineColor);
      });

      return colors;
    },
    tweaks(): string[] {
      if (!this.componentConfig.commonTweaks) {
        return [];
      }
      if (Array.isArray(this.componentConfig.commonTweaks)) {
        return this.componentConfig.commonTweaks;
      }
      return [this.componentConfig.commonTweaks];
    },
    chartClass(): string {
      let css = 'generic-line-chart';
      if (this.componentConfig.class) {
        css = css + ' ' + this.componentConfig.class;
      }
      if (this.isExporting) {
        css = css + ' exporting';
      }
      if (this.isClickable) {
        css = css + ' metric-container-order';
        if (!this.total) {
          // edge case - do not grey out metric when it's default one and has no data
          if (!(this.metricTitle === 'Active Orders' && this.isActive === true)) {
            css = css + ' metric-zero';
          }
        }
      }
      if (this.isActive) {
        css = css + ' active';
      }
      return css;
    },
    sideMetricsConfig(): object | null {
      return null;
    },
    showOneLine(): boolean {
      return this.componentConfig.showOneLine;
    },
    chartColors(): boolean {
      return this.componentConfig.chartColors;
    },
    // breakpoints(): boolean {
    //   return this.componentConfig.breakpoints;
    // },
  },
  methods: {
    totalCount(total): string | null {
      return utils.formatNumberWithCommas(total);
    },
    initChart(): void {
      if (this.componentConfig.canvasWidth) {
        this.canvasWidth = this.componentConfig.canvasWidth;
      }
      if (this.componentConfig.canvasHeight) {
        this.canvasHeight = this.componentConfig.canvasHeight;
      }
      try {
        setTimeout(() => {
          if (!this.hasEnoughData) {
            this.$emit('rendered', { empty: true });
            return;
          }
          if (!this.$refs.canvas) {
            this.$emit('rendered', { empty: true });
            return;
          }
          this.chartInstance = ECharts.init(this.$refs.canvas);
          if (this.chartInstance) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const c = this.chartInstance as any;
            if (this.isXLS) {
              this.getTableData();
            } else {
              c.on('finished', () => {
                this.$emit('rendered', { empty: false });
              });
              c.on('rendered', () => {
                this.$emit('rendered', { empty: false });
              });
            }
            c.setOption(this.chartConfig, true);
          } else {
            // eslint-disable-next-line no-console
            console.error('initChart', 'failed to create instance');
          }
        }, 10);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('initChart', error);
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onWindowResize(): any {
      setTimeout(() => {
        if (this.chartInstance) {
          // console.log('chartInstance line resize', this.chartInstance.id);
          this.chartInstance.resize();
        }
      }, 250);
    },
    getLegendKeyName(key: string): string {
      let name;
      switch (key) {
        case 'Impressions':
          name = key;
          break;
        case 'Hours':
          name = 'Viewing Hours';
          break;
        case 'ClickThrough':
          name = `CTR`;
          break;
        case 'CompletionRate':
          name = `VCR`;
          break;
        case 'GeoRetargetingImpressions':
          name = `Retargeting Imps`;
          break;
        case 'GeoBehaviorImpressions':
          name = `Behavioral Imps`;
          break;
        case 'GeoCookieImpressions':
          name = `Historical Imps`;
          break;
        case 'GeoFencingImpressions':
          name = `Geofencing Imps`;
          break;
        case 'GeoVisits':
          name = `Store Visits`;
          break;
        default:
          name = utils.headerNamesMap(key);
          break;
      }
      return name;
    },
    selectLine(value: string): void {
      if (value !== this.selectedLine) {
        this.selectedLine = value;
        this.initChart();
      }
    },
    usesPercent(key: string): boolean {
      return (
        key === 'ClickThrough' ||
        key === 'CompletionRate' ||
        key === 'CVR' ||
        key === 'VCR' ||
        key === 'LinkClickThrough' ||
        key === 'ImpressionPercent'
      );
    },
    getTableData(): void {
      let headers: string[] = [];
      if (this.componentConfig.exportTweaks?.addDayOfTheWeekColumn) {
        headers.push('Day');
      } else {
        headers.push('n/a'); // can we know?
      }
      headers = [...headers, ...this.componentConfig.lineSeriesKeys];

      // loop over chartData[0], those are the x axis values
      const data = [];
      if (Array.isArray(this.chartData) && Array.isArray(this.chartData[0])) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.chartData[0].forEach((key: any, index: number) => {
          const row = [];
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          this.chartData.forEach((serie: any[]) => {
            const val = serie[index];
            if (typeof val === 'string') {
              row.push(val.trim());
            } else {
              row.push(val);
            }
          });
          data.push(row);
        });
      }
      // console.log('line chart export', headers, data);
      if (data.length === 0) {
        this.$emit('rendered', { empty: true });
      } else {
        this.$emit('rendered', { empty: false, headers, data, config: this.componentConfig });
      }
    },
  },
});
